<template>
  <header>
    <nav class="navbar __navbar" role="navigation">
        <div class="container">
            <!-- Brand and toggle get grouped for better mobile display -->
            <div class="navbar-header">
                <button type="button" class="navbar-toggle" data-toggle="collapse" data-target=".navbar-ex1-collapse">
                    <span class="sr-only">Toggle navigation</span>
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                </button>
                <a href="#" class="navbar-brand __navbar-brand1" id="openSidebar"><i class=" fa fa-bars" onclick="openSidebar()" ></i></a>
                <a class="navbar-brand" href="index.html">
                    <img src="../assets/img/logo.png" class="__logoimg" />
                </a>
            </div>
    
            <!-- Collect the nav links, forms, and other content for toggling -->
            <div class="collapse navbar-collapse navbar-ex1-collapse">
                <!-- <ul class="nav navbar-nav">
                    <li class="active"><a href="#">Link</a></li>
                    <li><a href="#">Link</a></li>
                </ul> -->
                <ul class="nav navbar-nav navbar-right">
                    <li class="__navlink"><a href="" class="">Enterprise</a>
                    </li>
                    <li class="__navlink"><a href="price.html" class="">Pricing</a></li>
                    <li class="__navlink"><a href="company.html" class="">Company</a></li>
                    <li class="__navlink"><a href="" class="">Hiring</a></li>
                    <li class="__navlink"><a href="" class="">Talents</a></li>
                    <li class="__navlink"><a href="" class="">Mentors</a></li>

                    <li class="dropdown __dropdown">
                        <a href="#" class="dropdown-toggle __dropdown-droptoggle" data-toggle="dropdown">
                            userFirstName
                                <img src="../assets/img/profimg.png" class="__navbar__dropimg">
                        </a>
                        <ul class="dropdown-menu __dropdown__dropdown-menu">
                            <div class="row">
                                <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                    <img src="../assets/img/profimg.png" class="__dropdown_dropimg">
                                </div>
                                <div class="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-xs-10">
                                    <p class="__dropdown_p"> userAbout.fullName</p>
                                    <p class="__dropdown_p1">Professional Model | Fulltime</p>
                                    <a href="profile-edit.html" class="btn __dropdown_editbtn">Edit Profile</a>
                                    <a href="profile.html" class="btn __dropdown_viewbtn">View Profile</a>
                                </div>
                            </div>
                            <hr>
                            <a href="" class="__dropdown_savp"><img src="../assets/img/messageimg.png" class="__dropdown_iconimg">&nbsp;&nbsp;  My Messages</a>
                            <hr>
                            <a href="" class="__dropdown_savp"><img src="../assets/img/inperson.png" class="__dropdown_iconimg">&nbsp;&nbsp;  Saved Profile</a>
                            <hr>
                            <a href="" class="__dropdown_savp"><img src="../assets/img/folder.png" class="__dropdown_iconimg">&nbsp;&nbsp;  Account</a>
                            <hr>
                            <div class="row">
                                <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-xs-9">
                                    <p class="__dropdown_savp"><img src="../assets/img/briefcase.png" class="__dropdown_iconimg">&nbsp;&nbsp;  Available for Work</p>
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                    <input type="checkbox" class="switch_1">
                                </div>
                            </div>

                            <hr>
                            <div class="row">
                                <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-xs-9">
                                    <p class="__dropdown_savp"><img src="../assets/img/briefcase.png" class="__dropdown_iconimg">&nbsp;&nbsp;  Not available for Mentorship</p>
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                    <input type="checkbox" class="switch_1">
                                </div>
                            </div>
                            <a href="" class="__dropdown_savp"><img src="../assets/img/logoutimg.png" class="__dropdown_iconimg">&nbsp;&nbsp;  Log out</a>
                        </ul>
                    </li>
                </ul>
            </div><!-- /.navbar-collapse -->
        </div>
    </nav>
</header>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    ...mapActions(["logoutUser"]),
    async handleLogout() {
      await this.logoutUser();
      this.$router.push("/login");
    },
  },
};
</script>
<style lang="scss">
@import "../assets/css/dashboard.css";
</style>
